import React, { useEffect, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Container, Row, Col } from 'react-bootstrap'

const ContactUsForm = () => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const initialValues = {
    email: '',
    category: '',
    message: '',
    terms: false,
  }

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email format').required('Required'),
    category: Yup.string().required('Required'),
    message: Yup.string().required('Required'),
    terms: Yup.boolean()
      .oneOf([true], 'You must accept the terms and conditions')
      .required('Required'),
  })

  const onSubmit = (values) => {
    console.log('Form data', values)
  }

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <Row>
              <Col>
                <div style={{ textAlign: 'center' }}>
                  <label htmlFor='email'>Email</label>
                </div>
                <Field
                  id='email'
                  name='email'
                  type='email'
                  style={{
                    border: '1px solid black',
                    borderRadius: 8,
                    width: '100%',
                    padding: '8px',
                    marginBottom: '8px',
                    marginTop: '1rem',
                  }}
                />
                <ErrorMessage
                  name='email'
                  component='div'
                  className='text-danger'
                />
              </Col>
            </Row>
            <Row>
              <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                <label htmlFor='category'>Which best describes you?</label>
              </div>
              <div
                style={{
                  marginTop: '1rem',
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                }}
              >
                <Col md={6} xs={12}>
                  <div
                    role='group'
                    aria-labelledby='category-radio-group'
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <label>
                      <Field
                        type='radio'
                        name='category'
                        value='Individual'
                        style={{
                          backgroundColor: 'transparent',
                          border: '1px solid black',
                          marginRight: '8px',
                        }}
                      />
                      Individual
                    </label>
                    <label>
                      <Field
                        type='radio'
                        name='category'
                        value='Non-profit'
                        style={{
                          backgroundColor: 'transparent',
                          border: '1px solid black',
                          marginRight: '8px',
                        }}
                      />
                      Non-profit
                    </label>
                    <label>
                      <Field
                        type='radio'
                        name='category'
                        value='Startup'
                        style={{
                          backgroundColor: '#ffffff00',
                          border: '1px solid black',
                          marginRight: '8px',
                        }}
                      />
                      Startup
                    </label>
                  </div>
                </Col>
                <Col md={6} xs={12}>
                  <div
                    role='group'
                    aria-labelledby='category-radio-group'
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <label>
                      <Field
                        type='radio'
                        name='category'
                        value='Educational Institution'
                        style={{
                          backgroundColor: 'transparent',
                          border: '1px solid black',
                          marginRight: '8px',
                        }}
                      />
                      Educational Institution
                    </label>
                    <label>
                      <Field
                        type='radio'
                        name='category'
                        value='Small Business'
                        style={{
                          backgroundColor: 'transparent',
                          border: '1px solid black',
                          marginRight: '8px',
                        }}
                      />
                      Small Business
                    </label>
                    <label>
                      <Field
                        type='radio'
                        name='category'
                        value='Enterprise'
                        style={{
                          backgroundColor: 'transparent',
                          border: '1px solid black',
                          marginRight: '8px',
                        }}
                      />
                      Enterprise
                    </label>
                  </div>
                </Col>
              </div>
              <ErrorMessage
                name='category'
                component='div'
                className='text-danger'
              />
            </Row>
            <Row>
              <Col>
                <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                  <label htmlFor='message'>Message</label>
                </div>
                <Field
                  id='message'
                  name='message'
                  placeholder='Type your message...'
                  as='textarea'
                  rows={5}
                  style={{
                    border: '1px solid black',
                    borderRadius: 8,
                    width: '100%',
                    padding: '8px',
                    marginBottom: '8px',
                    marginTop: '1rem',
                  }}
                />
                <ErrorMessage
                  name='message'
                  component='div'
                  className='text-danger'
                />
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: '.5rem' }}>
                <label>
                  <Field
                    name='terms'
                    type='checkbox'
                    style={{
                      fontFamily: 'SK Concretica, sans-serif !important',
                      backgroundColor: 'transparent',
                      border: '1px solid black',
                      marginRight: '8px',
                    }}
                  />
                  I accept the{' '}
                  <a
                    href='/terms'
                    style={{
                      fontFamily: 'SK Concretica, sans-serif !important',
                      backgroundColor: 'transparent',
                      color: 'inherit',
                      fontSize: '16px',
                      textDecoration: 'underline',
                      marginRight: '8px',
                    }}
                  >
                    Terms
                  </a>
                </label>
                <ErrorMessage
                  name='terms'
                  component='div'
                  className='text-danger'
                />
              </Col>
            </Row>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '1rem',
              }}
            >
              <button className='cta-button-hero' style={{}} type='submit'>
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default ContactUsForm
