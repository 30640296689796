import React, { useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'
import { MdArrowOutward } from 'react-icons/md'

const ROICalculator = ({ region }) => {
  const [numberOfEmployees, setNumberOfEmployees] = useState(5)
  const [selectedOption, setSelectedOption] = useState('Monthly')

  const monthlyTeamPrice = region === 'GB' ? 19.99 : 24.99
  const quarterlyTeamPrice = region === 'GB' ? 16.99 : 19.99

  const totalPricePerMonth = (numberOfEmployees * monthlyTeamPrice).toFixed(2)
  const totalPricePerQuarter = (
    numberOfEmployees *
    quarterlyTeamPrice *
    3
  ).toFixed(2)
  const constSavingPerMonth = 30 * numberOfEmployees * 500000
  const constSavingPerQuarter = 30 * numberOfEmployees * 500000 * 3

  // const totalMonthlyROI =
  //   constSavingPerMonth / (numberOfEmployees * region === 'GB' ? 22.3 : 19.99)
  // const totalQuarterlyROI =
  //   constSavingPerQuarter /
  //   (numberOfEmployees * region === 'GB' ? 26.24 : 24.99)
  const totalMonthlyROI = constSavingPerMonth / totalPricePerMonth
  const totalQuarterlyROI = constSavingPerQuarter / totalPricePerQuarter

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  // Handle change when a radio button is clicked
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value)
  }

  return (
    <div className='main-pricing-team center-content'>
      <h1>Calculate Your Team's ROI with VerifiedX</h1>
      <p style={{ textAlign: 'center', fontSize: '1.125rem' }}>
        Discover how much you can save by protecting your team against scams and
        phishing attacks.
      </p>

      <div style={{ marginTop: '30px' }}>
        <div
          style={{
            padding: 16,
            border: 'none',
          }}
        >
          <div
            className='mx-auto'
            style={{
              fontSize: '24px',
              fontFamily: 'SK Concretica',
              marginBottom: '4rem',
              maxWidth: 600,
            }}
          >
            <Form>
              <Form.Group className='mb-3'>
                <div style={{ display: 'flex', gap: 10, flexWrap: 'wrap' }}>
                  <Form.Label style={{ width: 250 }}>
                    Number of Employees:
                  </Form.Label>
                  <Form.Control
                    type='number'
                    style={{ fontSize: 26, width: 'auto' }}
                    placeholder='Enter number of employees (minimum 5)'
                    value={numberOfEmployees}
                    onChange={(e) => setNumberOfEmployees(e.target.value)}
                    min={5}
                  />
                </div>
                <div
                  style={{
                    marginTop: 20,
                    display: 'flex',
                    gap: 10,
                    flexWrap: 'wrap',
                  }}
                >
                  <Form.Label style={{ width: 250 }}>
                    Billing Frequency:{' '}
                  </Form.Label>
                  <Form.Check
                    inline
                    label='Monthly'
                    name='group1'
                    type='radio'
                    id={`inline-radio-2`}
                    value='Monthly'
                    checked={selectedOption === 'Monthly'}
                    onChange={handleOptionChange}
                  />
                  <Form.Check
                    inline
                    label='Quarterly'
                    name='group1'
                    type='radio'
                    id={`inline-radio-2`}
                    value='Quarterly'
                    checked={selectedOption === 'Quarterly'}
                    onChange={handleOptionChange}
                  />
                </div>
              </Form.Group>
            </Form>
          </div>

          <div className='center-content' style={{ marginBottom: 50 }}>
            {!region ? (
              <Spinner animation='grow' />
            ) : (
              <>
                <Container fluid='md'>
                  {selectedOption === 'Monthly' && (
                    <Row>
                      <Col>&#x2713; Total Price per Month</Col>
                      <Col
                        md='3'
                        style={{
                          backgroundColor: '#c2c2c2',
                          padding: 10,
                          marginBottom: 10,
                          borderRadius: 10,
                          fontWeight: 'bold',
                        }}
                      >
                        {`${region === 'GB' ? '£' : '$'}${totalPricePerMonth}`}
                      </Col>
                    </Row>
                  )}

                  {selectedOption === 'Quarterly' && (
                    <Row>
                      <Col>&#x2713; Total Price per Quarter</Col>
                      <Col
                        md='3'
                        style={{
                          backgroundColor: '#c2c2c2',
                          padding: 10,
                          borderRadius: 10,
                          marginBottom: 10,
                          fontWeight: 'bold',
                        }}
                      >
                        {`${
                          region === 'GB' ? '£' : '$'
                        }${totalPricePerQuarter}`}
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      &#x2713; High-Risk Phishing Attempts per Employee per
                      Month
                    </Col>
                    <Col
                      md='3'
                      style={{
                        backgroundColor: '#c2c2c2',
                        padding: 10,
                        borderRadius: 10,
                        marginBottom: 10,
                        fontWeight: 'bold',
                      }}
                    >
                      30
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      &#x2713; Total High-Risk Phishing Attempts per Month
                    </Col>
                    <Col
                      md='3'
                      style={{
                        backgroundColor: '#c2c2c2',
                        padding: 10,
                        borderRadius: 10,
                        marginBottom: 10,
                        fontWeight: 'bold',
                      }}
                    >
                      {30 * numberOfEmployees}
                    </Col>
                  </Row>
                  <Row>
                    <Col>&#x2713; Average Cost per Successful Attack</Col>
                    <Col
                      md='3'
                      style={{
                        backgroundColor: '#c2c2c2',
                        padding: 10,
                        borderRadius: 10,
                        marginBottom: 10,
                        fontWeight: 'bold',
                      }}
                    >
                      $500,000
                    </Col>
                  </Row>

                  {selectedOption === 'Monthly' && (
                    <Row>
                      <Col>&#x2713; Potential Cost Savings per Month</Col>
                      <Col
                        md='3'
                        style={{
                          backgroundColor: '#c2c2c2',
                          padding: 10,
                          borderRadius: 10,
                          marginBottom: 10,
                          fontWeight: 'bold',
                        }}
                      >
                        ${numberWithCommas(constSavingPerMonth)}
                      </Col>
                    </Row>
                  )}

                  {selectedOption === 'Quarterly' && (
                    <Row>
                      <Col>&#x2713; Potential Cost Savings per Quarter</Col>
                      <Col
                        md='3'
                        style={{
                          backgroundColor: '#c2c2c2',
                          padding: 10,
                          borderRadius: 10,
                          marginBottom: 10,
                          fontWeight: 'bold',
                        }}
                      >
                        ${numberWithCommas(constSavingPerQuarter)}
                      </Col>
                    </Row>
                  )}

                  {selectedOption === 'Monthly' && (
                    <Row>
                      <Col>&#x2713; Estimated ROI per Month</Col>
                      <Col
                        md='3'
                        style={{
                          backgroundColor: '#c2c2c2',
                          padding: 10,
                          borderRadius: 10,
                          marginBottom: 10,
                          fontWeight: 'bold',
                        }}
                      >
                        ${numberWithCommas(totalMonthlyROI.toFixed(2))}
                      </Col>
                    </Row>
                  )}

                  {selectedOption === 'Quarterly' && (
                    <Row>
                      <Col>&#x2713; Estimated ROI per Quarter</Col>
                      <Col
                        md='3'
                        style={{
                          backgroundColor: '#c2c2c2',
                          padding: 10,
                          borderRadius: 10,
                          marginBottom: 10,
                          fontWeight: 'bold',
                        }}
                      >
                        ${numberWithCommas(totalQuarterlyROI.toFixed(2))}
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      &#x2713; Plus, avoid reputation damage and maintain
                      customer trust.
                    </Col>
                  </Row>
                </Container>
              </>
            )}
          </div>

          <a
            href={`${process.env.REACT_APP_VERIFIEDX_HOME}upgrade-plan`}
            style={{
              marginTop: 30,
              textDecoration: 'none',
              width: 'fit-content',
            }}
          >
            <div className='start-now-button'>
              PROTECT MY TEAM NOW{' '}
              <span style={{ fontSize: 20 }}>
                <MdArrowOutward size={20} />
              </span>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default ROICalculator
