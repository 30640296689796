export const usedByCompanies = [
  {
    company: 'Financial Services',
    uses: [
      'Protect financial transactions with 99.999% accuracy',
      'Mitigate fraud and AML compliance risks',
      'Safeguard confidential client data across all platforms',
      'Enhance efficiency with instant scam detection',
    ],
  },
  {
    company: 'Healthcare and Medical Practices',
    uses: [
      'Secure patient data to ensure HIPAA compliance',
      'Prevent ransomware attacks on EHR systems',
      'Streamline clinical workflows with quick scam verification',
      'Protect across all devices used in care delivery',
    ],
  },
  {
    company: 'Legal Services',
    uses: [
      'Safeguard attorney-client privileged communications',
      'Prevent spear-phishing and whaling attacks',
      'Maximize billable hours with seamless scam detection',
      'Ensure compliance with data protection regulations',
    ],
  },
  {
    company: 'Real Estate',
    uses: [
      'Secure property transaction communications',
      'Prevent wire fraud and escrow scams',
      'Enhance agent productivity with instant verification',
      'Manage team security across all devices',
    ],
  },
  {
    company: 'Retail and E-commerce',
    uses: [
      'Protect customer payment data to ensure PCI compliance',
      'Prevent brand impersonation and phishing',
      'Optimize operations with quick scam detection',
      'Monitor threats and ROI with detailed analytics',
    ],
  },
  {
    company: 'Education Service',
    uses: [
      'Safeguard student and faculty data under FERPA',
      'Prevent phishing attacks on campus networks',
      'Enhance learning environment with seamless protection',
      'Centralized security management for institutions',
    ],
  },
]

export const saveMillionsQuotes = [
  {
    quote: '66%',
    description: 'reduction in phishing incidents',
  },
  {
    quote: '$14 million',
    description: 'average savings per incident avoided',
  },
  {
    quote: '60%',
    description: 'improvement in employee awareness',
  },
]

export const testimonials = [
  {
    message:
      '"VerifiedX saved me from sending money to a scammer within seconds! I love how easy it is to use."',
    name: 'Luke Jevan',
    profession: 'Student',
  },
  {
    message:
      '"I use VerifiedX Team to protect my family. Now, my kids are all safe from online scammers."',
    name: 'Tan Choudhry',
    profession: 'Parent',
  },
  {
    message:
      '"VerifiedX has saved our company millions, by helping us proactively detect scam and data breach attempts."',
    name: 'Jed Ashforth',
    profession: 'Founder & CEO',
  },
]

export const faqsPricing = [
  {
    question: "How does VerifiedX's pricing work?",
    answer:
      'Our paid plans are Plus and Team. We offer monthly plans for Plus and monthly and quarterly plans per user for Team.',
  },
  {
    question:
      'Can I use VerifiedX in popular apps and collaboration platforms?',
    answer:
      'Yes. Our QuickCheck button works seamlessly across messaging, email, and social media apps, enabling easy verification without leaving your platform.',
  },
  {
    question: 'How does VerifiedX protect my data?',
    answer:
      'We employ robust encryption and stringent security measures to safeguard your data, ensuring utmost protection and privacy.',
  },
  {
    question: 'Can non-profits and educational institutions use VerifiedX too?',
    answer:
      "Yes. The VerifiedX Team plan is built for non-profits and educational institutions too. It's very affordable, provides the utmost detection accuracy and a great team management capability for managers.",
  },
  {
    question: 'Can parents protect their children with VerifiedX?',
    answer:
      'Yes. VerifiedX Team plan is built for family protection too. Parents can subscribe and add their children to their Team account, protecting their children against online scammers. Furthermore, VerifiedX is deliberately designed in such a way that kids can easily use it without any support.',
  },
  {
    question: 'Which language is supported?',
    answer:
      "English, currently. We're currently working to add more languages very soon.",
  },
]
