import React, { useEffect, useState } from 'react'
import PricingPlans from '../../components/pricing/PricingPlans'
import Testimonials from '../../components/pricing/Testimonials'
import FAQs from '../../components/pricing/FAQs'
import QuestionsCard from '../../components/pricing/QuestionsCard'
import axios from 'axios'

export default function Pricing() {
  const [region, setRegion] = useState('')

  useEffect(() => {
    // Fetch user's region based on IP address

    const getRegion = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json')
        const ipAddress = response.data.ip
        const userRegion = await axios.get(
          `https://ipapi.co/${ipAddress}/country/`
        )
        console.log(userRegion.data)
        setRegion(userRegion.data)
      } catch (e) {
        console.log(e)
      }
    }

    getRegion()
  }, [])

  return (
    <div
      style={{
        marginTop: 55,
        backgroundColor: '#e3e3e3',
      }}
    >
      <div
        className='center-content'
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: '90%',
        }}
      >
        <div
          style={{
            paddingTop: '7rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '768px',
          }}
        >
          <h1 style={{ fontSize: '64px' }}>Pricing</h1>
        </div>

        <PricingPlans region={region} />
        <Testimonials />
        <FAQs />

        <div
          style={{
            marginTop: '6.25rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '768px',
          }}
        >
          <h1 style={{ fontSize: '32px' }}>Still have questions?</h1>
          <p
            style={{
              textAlign: 'center',
              fontSize: '1.125rem',
              padding: '0px 60px',
            }}
          >
            Ask us anything through our contact form.
          </p>

          <a
            href='contact'
            style={{
              border: '1px solid #c2c2c2',
              backgroundColor: '#ffffff00',
              color: '#000',
              textDecoration: 'none',
              padding: '8px 24px',
              borderRadius: 5,
              marginTop: '6rem',
            }}
          >
            Contact
          </a>
        </div>

        <QuestionsCard
          marginTop='6.25rem'
          isReverse={false}
          image='https://media-public-verifiedx.s3.eu-north-1.amazonaws.com/Patt3+square.png'
          heading='Millions of people and businesses lose billions of dollars every year to scams and phishing.'
          btnLink={process.env.REACT_APP_VERIFIEDX_HOME}
          btnText='Protect yourself now'
        />
        <QuestionsCard
          marginTop='6.25rem'
          isReverse={true}
          imageBgColor='#fff'
          image='https://media-public-verifiedx.s3.eu-north-1.amazonaws.com/Patt1+Square+black.png'
          heading="Don't let yourself, your company, or a family member be one of them victims."
          btnLink={process.env.REACT_APP_VERIFIEDX_HOME}
          btnText='Try VerifiedX now'
        />
      </div>
    </div>
  )
}
