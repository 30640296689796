import React from 'react'
import { Card } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'
import { MdArrowOutward } from 'react-icons/md'

const Plus = ({ region }) => {
  return (
    <div
      className='center-content'
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <div
        className='drop-shadow'
        style={{ backgroundColor: '#F8F8F8', borderRadius: 10 }}
      >
        <div style={{ marginTop: '30px' }}>
          <Card
            style={{
              padding: 16,
              border: 'none',
              minHeight: 812,
            }}
          >
            <Card.Body>
              <Card.Title
                style={{ fontSize: '24px', fontFamily: 'SK Concretica' }}
              >
                <p>
                  <strong>Plus</strong>
                </p>
              </Card.Title>
              <div>
                <p
                  style={{
                    fontSize: 16,
                    paddingBottom: 20,
                    textAlign: 'left',
                  }}
                >
                  For individuals looking to protect themselves against scams
                  and phishing everywhere online
                </p>

                <ul
                  id='pricing-page-lists'
                  style={{ listStyleType: 'none', padding: 0 }}
                >
                  <li
                    style={{
                      fontSize: 16,
                      paddingBottom: 35,
                    }}
                  >
                    Easily paste anything for quick scam detection
                  </li>
                  <li
                    style={{
                      fontSize: 16,
                      paddingBottom: 35,
                    }}
                  >
                    99.999% accuracy
                  </li>
                  <li
                    style={{
                      fontSize: 16,
                      paddingBottom: 35,
                    }}
                  >
                    Protection in social media, emails, SMS, and all your
                    favourite apps
                  </li>
                  <li
                    style={{
                      fontSize: 16,
                      paddingBottom: 35,
                    }}
                  >
                    QuickCheck feature. Protection always just one click away
                  </li>
                  <li
                    style={{
                      fontSize: 16,
                      paddingBottom: 35,
                    }}
                  >
                    Concise explanations for each scam detected
                  </li>
                  <li
                    style={{
                      fontSize: 16,
                      paddingBottom: 35,
                    }}
                  >
                    Protection on all devices
                  </li>
                  <li
                    style={{
                      fontSize: 16,
                      paddingBottom: 35,
                    }}
                  >
                    Early access to new features
                  </li>
                </ul>
              </div>

              {!region ? (
                <Spinner animation='grow' />
              ) : (
                <>
                  <p
                    style={{
                      textAlign: 'left',
                      fontSize: 16,
                    }}
                  >
                    <span style={{ fontSize: 24 }}>
                      {region === 'GB' ? '£11.99' : '$15.99'}
                    </span>{' '}
                    / month
                  </p>
                </>
              )}
            </Card.Body>

            <a
              href={`${process.env.REACT_APP_VERIFIEDX_HOME}upgrade-plan`}
              style={{
                textDecoration: 'none',
                width: 'fit-content',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <div className='start-now-button' style={{ marginBottom: 16 }}>
                Start now{' '}
                <span style={{ fontSize: 20 }}>
                  <MdArrowOutward size={20} />
                </span>
              </div>
            </a>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default Plus
