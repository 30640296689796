import React, { useEffect, useState } from 'react'
import Plus from './Plus'
import Team from './Team'

const PricingPlans = ({ region }) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        gap: 8,
        marginTop: '7.5rem',
      }}
    >
      <Plus region={region} />
      <Team region={region} />
    </div>
  )
}

export default PricingPlans
