import React from 'react'
import { Nav } from 'react-bootstrap'
import { FaInstagramSquare, FaLinkedin } from 'react-icons/fa'
import { FaSquareXTwitter } from 'react-icons/fa6'

import { AiFillTikTok } from 'react-icons/ai'

export default function VerifiedFooter() {
  return (
    <footer
      className=''
      style={{
        position: 'relative',
        backgroundColor: '#323232',
        color: '#FFFFFF',
        fontFamily: 'SK Concretica, sans-serif',
        fontSize: '18px',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '7rem',
        padding: '20px 0',
      }}
    >
      <div expand='lg' className='footer-navbar shadow-sm'>
        <Nav
          className='ml-auto'
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Nav.Link className='nav-text' href='/' style={{ color: '#FFFFFF' }}>
            For Teams
          </Nav.Link>
          <Nav.Link
            className='nav-text'
            href='individuals'
            style={{ color: '#FFFFFF' }}
          >
            Individuals
          </Nav.Link>
          <Nav.Link
            className='nav-text'
            href='pricing'
            style={{ color: '#FFFFFF' }}
          >
            Pricing
          </Nav.Link>
        </Nav>
        <div
          className='social-icons'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <a
            href='https://twitter.com'
            className='social-icon'
            target='_blank'
            rel='noreferrer'
            style={{ color: '#39FF14', margin: '0 10px' }}
          >
            <FaSquareXTwitter size={36} />
          </a>
          <a
            href='https://www.tiktok.com/@verifiedx?_t=8niqgoz4c6b&_r=1'
            className='social-icon'
            target='_blank'
            rel='noreferrer'
            style={{ color: '#39FF14', margin: '0 10px' }}
          >
            <AiFillTikTok size={40} />
          </a>
          <a
            href='https://www.linkedin.com/company/verifiedx/'
            className='social-icon'
            target='_blank'
            rel='noreferrer'
            style={{ color: '#39FF14', margin: '0 10px' }}
          >
            <FaLinkedin size={36} />
          </a>
          <a
            href='https://www.instagram.com/verifiedx.me?igsh=aGV0cjl1M2FvYXF6&utm_source=qr'
            className='social-icon'
            target='_blank'
            rel='noreferrer'
            style={{ color: '#39FF14', margin: '0 10px' }}
          >
            <FaInstagramSquare size={36} />
          </a>
        </div>
        {/* <Form className='newsletter-signup'>
          <Form.Label
            className='mr-2 stay-updated '
            style={{ color: '#FFFFFF', marginRight: '2rem' }}
          >
            Stay updated with the latest in scam protection.
          </Form.Label>
          <Form.Control
            type='email'
            placeholder='Enter your email'
            className='mr-2 footer-input'
          />
          <button type='submit' className='cta-button-hero'>
            Sign Up
          </button>
        </Form> */}
      </div>
    </footer>
  )
}
