import React from 'react'
import { Card } from 'react-bootstrap'
import { MdArrowOutward } from 'react-icons/md'
import Spinner from 'react-bootstrap/Spinner'

export default function PricingTeam({ region }) {
  return (
    <div className='main-pricing-team center-content'>
      <div
        className='center-content'
        style={{
          maxWidth: '580px',
        }}
      >
        <h1>Get started with VerifiedX today</h1>
        <a href='pricing' style={{ textDecoration: 'none', color: 'inherit' }}>
          <p
            style={{ textAlign: 'center', fontSize: '1.125rem', marginTop: 30 }}
          >
            View pricing plans &gt;
          </p>
        </a>
      </div>

      <div
        className='drop-shadow'
        style={{ backgroundColor: '#F8F8F8', borderRadius: 10 }}
      >
        <div style={{ marginTop: '30px' }}>
          <Card
            style={{
              padding: 16,
              border: 'none',
            }}
          >
            <Card.Body>
              <Card.Title
                style={{ fontSize: '24px', fontFamily: 'SK Concretica' }}
              >
                <p>
                  <strong>Team</strong>
                </p>
              </Card.Title>
              <div>
                <ul
                  id='pricing-list'
                  style={{ listStyleType: 'none', padding: 0 }}
                >
                  <li
                    style={{
                      fontSize: 16,
                      paddingBottom: 35,
                    }}
                  >
                    QuickCheck button for quick message check
                  </li>
                  <li
                    style={{
                      fontSize: 16,
                      paddingBottom: 35,
                    }}
                  >
                    Verify messages without leaving apps
                  </li>
                  <li
                    style={{
                      fontSize: 16,
                      paddingBottom: 35,
                    }}
                  >
                    Clear explanations for every detection
                  </li>
                  <li
                    style={{
                      fontSize: 16,
                      paddingBottom: 35,
                    }}
                  >
                    Advanced AI with 99.999% accuracy
                  </li>
                  <li
                    style={{
                      fontSize: 16,
                      paddingBottom: 35,
                    }}
                  >
                    Protection on all devices
                  </li>
                  <li
                    style={{
                      fontSize: 16,
                      paddingBottom: 35,
                    }}
                  >
                    Admin console for team members management
                  </li>
                  <li
                    style={{
                      fontSize: 16,
                      paddingBottom: 35,
                    }}
                  >
                    Test 5x more messages and links
                  </li>
                  <li
                    style={{
                      fontSize: 16,
                      paddingBottom: 35,
                    }}
                  >
                    Useful ROI and team protection analytics
                  </li>
                  <li
                    style={{
                      fontSize: 16,
                      paddingBottom: 35,
                    }}
                  >
                    Priority customer support
                  </li>
                </ul>
              </div>

              {!region ? (
                <Spinner animation='grow' />
              ) : (
                <>
                  <p
                    style={{
                      textAlign: 'left',
                      fontSize: 16,
                    }}
                  >
                    <span style={{ fontSize: 24 }}>
                      {region === 'GB' ? '£16.99' : '$19.99'}
                    </span>{' '}
                    per user / month billed quarterly
                  </p>
                  <p
                    style={{
                      textAlign: 'left',
                      fontSize: 16,
                    }}
                  >
                    <span style={{ fontSize: 24 }}>
                      {region === 'GB' ? '£19.99' : '$24.99'}
                    </span>{' '}
                    per user / month billed monthly
                  </p>
                </>
              )}

              <p
                style={{
                  textAlign: 'left',
                  fontSize: 16,
                }}
              >
                *10+ users: additional 10% discount on the total price`
              </p>
              <p
                style={{
                  textAlign: 'left',
                  fontSize: 16,
                }}
              >
                *20+ users: 15% discount on the total price
              </p>

              <a
                href={`${process.env.REACT_APP_VERIFIEDX_HOME}upgrade-plan`}
                style={{
                  textDecoration: 'none',
                  width: 'fit-content',
                }}
              >
                <div className='start-now-button'>
                  Start now{' '}
                  <span style={{ fontSize: 20 }}>
                    <MdArrowOutward size={20} />
                  </span>
                </div>
              </a>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  )
}
